var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        "append-to-body": "",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c("div", { staticClass: "dia-tit not-title" }, [
        _c("i"),
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "el-form",
        {
          attrs: {
            "label-width": "100px",
            model: _vm.formData,
            rules: _vm.rules,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "模板上传：" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    staticClass: "upload-demo",
                    attrs: {
                      "auto-upload": true,
                      drag: "",
                      "before-remove": _vm.beforeRemove,
                      "on-remove": _vm.handleAvatarRemove,
                      "file-list": _vm.fileList,
                      "on-success": _vm.handleAvatarSuccess,
                      "on-error": _vm.handleAvatarFail,
                      headers: _vm.headers,
                      "on-exceed": _vm.handleExceed,
                      accept: _vm.acceptType,
                      action: _vm.uploadUrl,
                      "on-change": _vm.handleChange,
                      limit: 1,
                      data: _vm.myData,
                      name: "file",
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v("将文件拖到此处，或"),
                      _c("em", [_vm._v("点击上传")]),
                    ]),
                    _vm.formData.scratchType === "scratchjr"
                      ? _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [_vm._v("温馨提示：支持上传sjr类型的文件！")]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [_vm._v("温馨提示：支持上传sb3类型的文件！")]
                        ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("el-row", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }