<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="60%"
    append-to-body
    top="7vh"
  >
    <div class="dia-tit not-title">
      <i></i>
      <span>{{ title }}</span>
    </div>
    <el-form  label-width="100px" :model="formData" :rules="rules" >
      <!-- <el-form-item label="模板创建：">
        <el-select v-model="formData.scratchType" placeholder="请选择搜索类型" @change="handleSelectChange">
          <el-option
            v-for="item in scratchOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button v-if="formData.scratchType==='scratchjr'" @click="goScratchJr" type="primary" style="margin-left: 0px">在线创建</el-button>
      </el-form-item> -->
      <el-form-item label="模板上传：">
        <div style="display: flex">
          <el-upload
            :auto-upload="true"
            drag
            :before-remove="beforeRemove"
            :on-remove="handleAvatarRemove"
            :file-list="fileList"
            :on-success="handleAvatarSuccess"
            :on-error="handleAvatarFail"
            ref="upload"
            :headers="headers"
            :on-exceed="handleExceed"
            :accept="acceptType"
            :action="uploadUrl"
            :on-change="handleChange"
            :limit="1"
            :data="myData"
            class="upload-demo"
            name="file"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip" v-if="formData.scratchType==='scratchjr'">温馨提示：支持上传sjr类型的文件！</div>

            <div class="el-upload__tip" slot="tip" v-else>温馨提示：支持上传sb3类型的文件！</div>

<!--            <el-button size="small" slot="trigger" type="primary">选取文件</el-button>-->
<!--            <el-button-->
<!--              @click="submitUpload()"-->
<!--              size="small"-->
<!--              style="margin-left: 10px;"-->
<!--              type="success"-->
<!--            >上传到服务器</el-button>-->
<!--            <div class="el-upload__tip" slot="tip"></div>-->
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
    </el-row>
    <!-- <el-dialog
      :visible.sync="scratchJrModalVisible"
      :fullscreen="true"
      :modal="false"
      :lock-scroll="true"
      :destroy-on-close="true"
      class="mould-style"
    >
      <ScratchJrMould @getScratchJrInfo='getScratchJrInfo' v-if='scratchJrModalVisible'/>
    </el-dialog> -->
  </el-dialog>
</template>

<script>
  import { getToken } from '@/utils/auth'
  import ScratchJrMould from '@/views/ScratchJr/mould'
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      title:{
        type: String,
        default: '',
      }
    },
    components:{ScratchJrMould},
    computed: {
      myData(){
        return {
          type: 'scratch',
        }
      }
    },
    data () {
      return {
        scratchJrModalVisible: false,
        acceptType:'.sb3',
        scratchOptions:[
          {
            value:'scratchjr',
            label:'scratchjr',
          },{
            value:'scratch',
            label:'scratch',
          }
        ],
        uploadUrl: process.env.VUE_APP_API_ADDRESS + 'srcTpl/upload',
        fileList:[],
        formData:{
          scratchType:'scratch'
        },
        rules: {
          // scratchType: [{ required: true, message: '请选择模板类型', trigger: 'blur' }],
        },
        shouldChooseId:"",
        headers: {
          "Authorization":getToken()
        },
        scratchJrInfo: null,
      }
    },
    methods: {
      handleSelectChange() {
        this.myData.type = this.formData.scratchType
        this.acceptType =  this.formData.scratchType ==='scratch'?'.sb3':'.sjr'
      },
      goScratchJr(){
        const routeData = this.$router.resolve({
          name: 'ScratchJrMould',
          query: {}
        })
        window.open(routeData.href, "_blank")
      },
      submitUpload() {
      if (this.fileList.length === 0) {
          $msg("请先选择模板文件", 2);
          return false;
        } else {
          this.$refs.upload.submit();
        }
      },
      handleChange(file, fileList) {
        this.fileList = fileList.slice(-1);
      },
      handleAvatarFail(err, res, file) {
        $msg("上传文件失败", 2);
      },
      handleAvatarSuccess(res, file) {
        if (res && res.state == "success") {
          this.$refs.upload.clearFiles()
          this.shouldChooseId = res.body
          $msg("上传文件成功", 0);
          this.close()
        } else {
          $msg("上传文件失败", 2);
        }
      },

      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${
            files.length
            } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        );
      },
      handleAvatarRemove(file, fileList) {
        this.fileList = fileList;
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      openDialog() {
        this.shouldChooseId = ""
        this.formData = {
          scratchType:''
        }
      },
      addScratchModule() {

      },
      close() {
        this.$emit("scratchmoduleList",this.shouldChooseId)
        this.$emit("close")
      },
      getScratchJrInfo(val){
        if(val){
          this.scratchJrModalVisible = false;
        this.$emit("scratchmoduleList")
          this.close();
          this.$message.success('保存成功！');
        }else{
          this.scratchJrModalVisible = false;
          this.$message.info('保存失败请重试！');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-dialog__body {
    // padding: 0 !important;
  }
  .tag {
    display: inline-block;
    color: #fff;
    border-radius: 17px 20px 0px 17px;
    padding: 3px 15px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  @import "@/style/dialog.scss";
</style>
<style  lang="scss">
.mould-style{
  .el-dialog__header {
    padding: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    right: 0;
    .el-dialog__headerbtn {
      position: absolute;
      right: 50px;
      top: 12px;
      color: #000;
      z-index: 99999;
    }
  }
  .el-icon-close:before{
    width: 20px;
    height: 20px;
    content: 'X';
  }
  .el-icon-close:hover{
    color: #000;
  }
  .el-dialog__body {
    padding: 0 !important;
    width: calc(100vw);
    height: calc(100vh);
    overflow: hidden;
  }
}
</style>
